/**!
 *  Fuse API functionality
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import API from "../API";
import Auth from "../Auth";
import Globals from "../Globals";

class Fuse
{
    /**
     * Attempt to fetch a comment from Fuse. Since there is no endpoint to
     * fetch a single comment, we need to fetch all comments for the content
     * and search for the comment within it.
     * 
     * @param string|integer contentId - The content id.
     * @param string|integer commentId - The comment id.
     * @param function callback - Callback when finished.
     * @param boolean refresh - Force fresh API call.
     * @return object|boolean - The comment object or 'false' when not found.
     */

    Comment = (contentId, commentId, callback, refresh) =>
    {
        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        if (!refresh && this.Comments[commentId] !== undefined)
        {
            callback(this.Comments[commentId]);
        }
        else
        {
            this.Request(`contents/${contentId}/comments`, {per_page: 200}, response =>
            {
                const {comments} = response;
                // Create a walker function to parse the comments recursively.
                const Walker = level =>
                {
                    level.forEach( c =>
                    {
                        const {id, children} = c;
                        this.Comments[id] = c;
                        Walker(children);
                    });
                };
                Walker(comments);
                if (this.Comments[commentId] !== undefined)
                {
                    callback(this.Comments[commentId]);
                }
                else
                {
                    callback(false);
                }
            });
        }
    }

    /**
     * Fetch content from Fuse.
     * 
     * @param string|integer id - Fuse content ID.
     * @param string type - Fuse content type.
     * @param function callback - Callback when the content has been received.
     * @return void
     */

    Content = (id, type, callback) =>
    {
        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        const Id = parseInt(id, 10);
        if (!Id || isNaN(Id))
        {
            callback(false);
        }
        else
        {
            switch (type)
            {
                case "community":
                    this.Request(`communities/${Id}`, response =>
                    {
                        if (!response || response.id !== Id)
                        {
                            callback(false);
                        }
                        else
                        {
                            const {description, name, icon: preview} = response;
                            callback({
                                accessible: true,
                                description,
                                id: Id,
                                name,
                                preview,
                                raw: response,
                                type
                            });
                        }
                    });
                    break;
                case "learningplan":
                    this.Request(`learning_plans/${Id}`, response =>
                    {
                        if (!response || !response.learning_plan || response.learning_plan.id !== Id)
                        {
                            callback(false);
                        }
                        else
                        {
                            const {thumbnail_3x_url: preview, title: name} = response.learning_plan;
                            callback({
                                accessible: true,
                                description: "",
                                id: Id,
                                name,
                                preview,
                                raw: response.learning_plan,
                                type
                            });
                        }
                    });
                    break;
                case "topic":
                    this.Request(`topics/${Id}`, response =>
                    {
                        if (!response || response.id !== Id)
                        {
                            callback(false);
                        }
                        else
                        {
                            const {accessible, name, icon_url: preview} = response;
                            callback({
                                accessible,
                                description: "",
                                id: Id,
                                name,
                                preview,
                                raw: response,
                                type
                            });
                        }
                    });
                    break;
                case "user":
                    this.Request(`users/${Id}`, response =>
                    {
                        if (!response || response.id !== Id)
                        {
                            callback(false);
                        }
                        else
                        {
                            const {name, avatar_url: preview} = response;
                            callback({
                                accessible: true,
                                description: "",
                                id: Id,
                                name,
                                preview,
                                raw: response,
                                type
                            });
                        }
                    });
                    break;
                default:
                    this.Request(`contents/${Id}`, response =>
                    {
                        if (!response || response.id !== Id)
                        {
                            callback(false);
                        }
                        else
                        {
                            const {accessible, content_type, description, name, preview} = response;
                            callback({
                                accessible,
                                description,
                                id: Id,
                                name,
                                preview,
                                raw: response,
                                type: type || content_type.toLowerCase()
                            });
                        }
                    });
            }
        }
    }

    ContentName = (item, callback) =>
    {
        let Id = typeof item === "object" ? item[0] : item;
        let Type = typeof item === "object" ? item[1] : 0;
        if (this.ContentNames[Id] !== undefined)
        {
            callback(this.ContentNames[Id]);
        }
        else if (Type)
        {
            this.Content(Id, Type, content =>
            {
                if (!content)
                {
                    callback("");
                }
                else
                {
                    callback(this.ContentNames[Id] = content.name);
                }
            });
        }
        else
        {
            API.Request("content/cache-read", {id: Id}, response =>
            {
                const {cache, error} = response;
                if (error)
                {
                    callback("");
                }
                else
                {
                    this.Content(Id, cache.type, content =>
                    {
                        if (!content)
                        {
                            callback("");
                        }
                        else
                        {
                            callback(this.ContentNames[Id] = content.name);
                        }
                    });
                }
            });
        }
    }

    /**
     * Create a Fuse content URL
     * 
     * @param string|integer id - Fuse content ID.
     * @param string type - Fuse content type.
     * @return string - The URL
     */

    ContentUrl = (id, type) =>
    {
        switch (type.toLowerCase())
        {
            case "community":
            case "privatecommunity":
            case "protectedcommunity":
                return this.FuseUrl + `communities/${id}`;
            case "learningplan":
            case "learning-plan":
                return this.FuseUrl + `learning/plans/${id}`;
            case "topic":
                return this.FuseUrl + `topics/${id}`
            case "user":
                return this.FuseUrl + `users/${id}`;
            default:
                return this.FuseUrl + `contents/${id}`;
        }
    }

    /**
     * Decode a Fuse API response.
     * 
     * @param string response - Unparsed response.
     * @return object|bool - Decoded JSON or 'false' on fail.
     */

    Decode = (response) =>
    {
        return JSON.parse(response);
    }

    /**
     * Get inject variables.
     * 
     * @return object - Variable object.
     */

    InjectVars = () =>
    {
        const {name} = Auth.User || {};
        const [Name, GivenName, FamilyName] = name || [];
        return {
            familyName: ["Family name", FamilyName || ""],
            givenName: ["Given name", GivenName || ""],
            name: ["Name", Name || ""]
        };
    }

    /**
     * Make a XHR request to the Fuse API.
     * 
     * @param string endpoint
     * @param object data - Optional form data.
     * @param function callback - Called when the request is completed.
     * @param string method - Request method. Defaults to 'GET'.
     * @param boolean overrideUrl - Whether to parse endpoint as override URL.
     * @return object - The XHR object.
     */

    Request = (endpoint, data, callback, method = "GET", overrideUrl = false) =>
    {
        if (!Auth.Token)
        {
            console.error("Fuse credentials are not ready.");
            return false;
        }
        // Since data is optional, the second parameter may instead
        // be the callback method.
        if (!callback)
        {
            callback = data;
        }
        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        const Data = data || {};
        const Query = [`auth_token=${Auth.Token}`];
        let FormData = null, Json = false;
        if (method === "POST")
        {
            FormData = API.FormData(Data);
        }
        else if (method === "JSON")
        {
            method = "POST";
            Json = true;
            FormData = JSON.stringify(Data);
        }
        else
        {
            for (let key in Data)
            {
                Query.push(key + "=" + encodeURIComponent(Data[key]));
            }
        }
        const Url = overrideUrl ? endpoint : this.Url(endpoint + "?" + Query.join("&"));
        if (!Url)
        {
            callback(false);
            console.error("No Fuse API URL has been configured.");
            return false;
        }
        const Xhr = new XMLHttpRequest();
        Xhr.open(method, Url, true);
        if (Json)
        {
            Xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        }
        // On complete.
        Xhr.addEventListener("load", (e) =>
        {
            // Check for 200.
            if (Xhr.readyState !== XMLHttpRequest.DONE)
            {
                return;
            }
            // Check that the response is a proper JSON.
            const Response = this.Decode(Xhr.response);
            callback(Response);
        }, false);
        // On fail.
        Xhr.addEventListener("error", (e) =>
        {
            callback(false);
        }, false);
        Xhr.send(FormData);
        return Xhr;
    }

    Search = (query, community, types = [], callback) =>
    {
        const Payload = {q: query};
        this.Request("search/new", Payload, callback);
    }

    SearchNonFunctional = (query, community, types = [], callback, offset = 0, sort = "relevancy", language = "en-GB") =>
    {
        const Filters = {};
        if (types.length)
        {
            Filters.type = types;
        }
        if (community)
        {
            //Filters.communityNamesAcl = {communityName: [community]};
        }
        const Data = {
            filters: Filters,
            from: offset,
            language,
            query,
            sort
        };
        const Url = `${this.FuseUrl}api/content/search/v1.1/query?userHash=${Auth.Token}`;
        this.Request(Url, Data, callback, "JSON", true);
    }

    SetContext = (context, contextId) =>
    {
        this.Context = context;
        this.ContextId = contextId;
    }

    SetHost = (host) =>
    {
        if (this.OriginAllowed.indexOf(host) < 0 || this.FuseOverride.indexOf(host) >= 0)
        {
            host = this.FuseDefault;
        }
        console.log("Set Host", host);
        const Version = Globals.Setting("FuseApiVersion", 4.2);
        this.ApiUrl = `${host}/api/v${Version}/`;
        this.FuseUrl = `${host}/`;
    }

    Setup()
    {
        this.ApiUrl = "";
        this.Comments = {};
        this.ContentNames = {};
        this.Context = "";
        this.ContextId = 0;
        this.FuseUrl = "";
        this.FuseDefault = Globals.Setting("FuseDefault");
        this.FuseOverride = Globals.Setting("FuseOverride", []);
        this.OriginAllowed = Globals.Setting("OriginAllowed", []);
    }

    /**
     * Create a Fuse URL.
     * 
     * @param string uri - The request URI.
     * @param boolean api - Whether this should be an API URL. Defaults to true.
     * @return string - The URL
     */

    Url = (uri, api = true) =>
    {
        if (api)
        {
            return this.ApiUrl ? this.ApiUrl + uri : '';
        }
        return this.FuseUrl ? this.FuseUrl + uri : '';
    }
}

export default new Fuse();