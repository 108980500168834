import React from "react";
import API from "Class/API";
import Broadcast from "Class/Broadcast";
import Globals from "Class/Globals";
import Fuse from "Class/Fuse";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ViewAlert from "Components/View/Alert";
import ViewArticles from "Components/View/Articles";
import ViewDialog from "Components/View/Dialog";
import ViewEditor from "Components/View/Editor";
import ViewNavigation from "Components/View/Navigation";
import ViewWidget from "Components/View/Widget";

API.Setup();
Broadcast.Setup();
Fuse.Setup();

class Core extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Router = false;
        this.Touch = "ontouchstart" in window;
    }

    /**
     * Broadcast on mount.
     * @return void
     */

    componentDidMount()
    {
        Broadcast.SendMessage({type: "load"});
        this.AddScripts();
    }

    /**
     * Append external JS to DOM.
     * @return void
     */

    AddScripts = () =>
    {
        // WalkMe
        window._walkmeConfig = {smartLoad: true};
        const WalkMe = document.createElement("script");
        WalkMe.async = true;
        WalkMe.type = "text/javascript";
        WalkMe.src = "https://urldefense.com/v3/__https:/cdn.walkme.com/users/1bab621e18264b0daaa0f617542b41ac/test/walkme_1bab621e18264b0daaa0f617542b41ac_https.js__;!!OrxsNty6D4my!8AL_zHtDXaCqmNWBwVqqvLAKn8PWCaKg2j9PAfLdjmQ8z6Xirkd9TnQFKusMGCJdOFJ6Y3PSdGKxh9Hsa3x1n8b6Zjo$";
        document.body.appendChild(WalkMe);

    }

    /**
     * Set title on navigation.
     * Scroll up on forward navigation.
     * @return void
     */

    OnNavigation = (location) =>
    {
        const Path = location.pathname;
        // Set global path var.
        Globals.Var("path", Path.length > 1 ? Path.substr(1) : Path);
        if (location.action === "POP")
        {
            return;
        }
        window.scrollTo(0, 0);
    }

    /**
     * Add navigation listener on Router mount.
     * @return void
     */

    OnRouter = (router) =>
    {
        if (!router)
        {
            return;
        }
        const Path = router.history.location.pathname;
        // Set global path var.
        Globals.Var("path", Path.length > 1 ? Path.substr(1) : Path);
        this.Router = router;
        router.history.listen(this.OnNavigation);
    }

    /**
     * Load alert dialog
     * @return void
     */

    RouteAlert = (routeProps) =>
    {
        const {context} = routeProps.match.params;
        const [ContextName, ContextId] = this.SetContext(context);
        return <ViewAlert context={ContextName} contextId={ContextId}/>;
    }

    /**
     * Load article editor
     * @return void
     */

    RouteArticles = (routeProps) =>
    {
        const {context} = routeProps.match.params;
        const [ContextName, ContextId] = this.SetContext(context);
        return <ViewArticles context={ContextName} contextId={ContextId}/>;
    }

    /**
     * Load widget editor
     * @return void
     */

    RouteEditor = (routeProps) =>
    {
        const {context} = routeProps.match.params;
        const [ContextName, ContextId] = this.SetContext(context);
        return <ViewEditor context={ContextName} contextId={ContextId}/>;
    }

    /**
     * Load requested widget.
     * @return void
     */

    RouteWidget = (routeProps) =>
    {
        const {context, name} = routeProps.match.params;
        const [ContextName, ContextId] = this.SetContext(context);
        return <ViewWidget
            context={ContextName}
            contextId={ContextId}
            name={name }
        />;
    }

    /**
     * Extract and set context + host.
     * @param string context - Unparsed context.
     * @return array - [ ContextName, ContextId, Host ];
     */

    SetContext = (context) =>
    {
        const Context = context ? atob(context).split("__") : [];
        const [ContextName, ContextId, Host, Location] = Context || [];
        console.log("Context", Context);
        API.SetHost(Host);
        Globals.Var("context", [ContextName, ContextId, Host]);
        Globals.Var("location", Location);
        Fuse.SetHost(Host);
        Broadcast.SetFuseHost(Host);
        return [ContextName, ContextId, Host];
    }

    render()
    {
        const CA = ["Core"];
        if (this.Touch)
        {
            CA.push("Touch");
        }
        else
        {
            CA.push("NoTouch");
        }
        return (
            <Router ref={this.OnRouter}>
                <div className={CA.join(" ")}>
                    <ViewDialog />
                    <Switch>
                        <Route path="/alert/:context" render={this.RouteAlert}/>
                        <Route path="/articles/:context" render={this.RouteArticles}/>
                        <Route path="/editor/:context" render={this.RouteEditor}/>
                        <Route path="/widget/:name/:context" render={this.RouteWidget}/>
                        <Route component={ViewNavigation}/>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default Core;